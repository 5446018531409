import React, { useEffect } from "react";
import gsap from "gsap";
import Navbar from "../components/Navbar";
import Separator from "../components/separator";
import Footer from "../components/Footer";
import ScrollTrigger from "gsap/src/ScrollTrigger";
const Home = () => {
    useEffect(() => {
        const elts = {
            text1: document.getElementById("text1"),
            text2: document.getElementById("text2")
        };

        const texts = [
            "ELEKTRITÖÖD",
            "ELEKTRIKILPIDE VALMISTAMINE",
            "KÄIDUKORRALDUS",
            "AUTOMAATIKA LAHENDUSED",
            "ELEKTRIALANE KONSULATATSIOON"
        ];

        const morphTime = 1;
        const cooldownTime = 0.25;

        let textIndex = texts.length - 1;
        let time = new Date();
        let morph = 0;
        let cooldown = cooldownTime;

        elts.text1.textContent = texts[textIndex % texts.length];
        elts.text2.textContent = texts[(textIndex + 1) % texts.length];

        function doMorph() {
            morph -= cooldown;
            cooldown = 0;

            let fraction = morph / morphTime;

            if (fraction > 1) {
                cooldown = cooldownTime;
                fraction = 1;
            }

            setMorph(fraction);
        }

        function setMorph(fraction) {
            elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
            elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

            fraction = 1 - fraction;
            elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
            elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

            elts.text1.textContent = texts[textIndex % texts.length];
            elts.text2.textContent = texts[(textIndex + 1) % texts.length];
        }

        function doCooldown() {
            morph = 0;

            elts.text2.style.filter = "";
            elts.text2.style.opacity = "100%";

            elts.text1.style.filter = "";
            elts.text1.style.opacity = "0%";
        }

        function animate() {
            requestAnimationFrame(animate);

            let newTime = new Date();
            let shouldIncrementIndex = cooldown > 0;
            let dt = (newTime - time) / 2000;
            time = newTime;

            cooldown -= dt;

            if (cooldown <= 0) {
                if (shouldIncrementIndex) {
                    textIndex++;
                }

                doMorph();
            } else {
                doCooldown();
            }
        }
        gsap.registerPlugin(ScrollTrigger)
        if (typeof InstallTrigger !== 'undefined' === true) {
            document.getElementById("text1").style.display = "none"
            document.getElementById("text2").style.display = "none"
        } else {
            animate();
        }
        gsap.to(".I-t--", {opacity:1})
        gsap.to(".I-f--", {opacity:1})
        gsap.to(".txt-p-e", {
            scrollTrigger: ".container-I", // start the animation when ".box" enters the viewport (once)
            opacity: 1,
            y: -24,

            delay: 0.2,
            duration: 0.5
        });
        gsap.to(".txt-h-e", {
            scrollTrigger: ".container-I", // start the animation when ".box" enters the viewport (once)
            opacity: 1,
            y: -24,

            delay: 0.3,
            duration: 0.5
        });
        gsap.to(".sep-e", {
            scrollTrigger: ".container-I", // start the animation when ".box" enters the viewport (once)
            opacity: 1,
            y: -24,

            delay: 0.3,
            duration: 0.5
        });

        gsap.to(".txt-h-t", {
            scrollTrigger: ".container-II", // start the animation when ".box" enters the viewport (once)
            opacity: 1,
            y: -24,

            delay: 0.2,
            duration: 0.5
        });
        gsap.to(".sep-t", {
            scrollTrigger: ".container-II", // start the animation when ".box" enters the viewport (once)
            opacity: 1,
            y: -24,

            delay: 0.2,
            duration: 0.5
        });
        gsap.to(".txt-p-t", {
            scrollTrigger: ".container-II", // start the animation when ".box" enters the viewport (once)
            opacity: 1,
            y: -24,
           
            delay: 0.2,
            duration: 0.5
        });

        gsap.to(".deco-I", {
            scrollTrigger: ".deco-I", // start the animation when ".box" enters the viewport (once)
            opacity: 1,
            y: -24,
           
            delay: 0.2,
            duration: 0.5
        });

        gsap.to(".txt-p-p", {
            scrollTrigger: ".txt-p-p", // start the animation when ".box" enters the viewport (once)
            opacity: 1,
            y: -24,
           
            delay: 0.2,
            duration: 0.5
        });
        gsap.to(".txt-h-p", {
            scrollTrigger: ".txt-h-p", // start the animation when ".box" enters the viewport (once)
            opacity: 1,
            y: -24,
           
            delay: 0.2,
            duration: 0.5
        });
        gsap.to(".sep-p", {
            scrollTrigger: ".sep-p", // start the animation when ".box" enters the viewport (once)
            opacity: 1,
            y: -24,
           
            delay: 0.2,
            duration: 0.5
        });
    }, [])
    

    return (
        <div class="overflow-x-hidden">
            <div class="h-full w-full overflow-hidden">
                <img alt="" class="absolute right-0 bg-main h-full w-2/3 bg-cover bg-left">{}</img>
                <div class="absolute bg-linear-main h-full w-full">
                    <div id="div-content-I" class="h-full items-center flex w-full pl-24">
                        <div class="w-full">
                            <div id="container">
                                <h1 id="text1" class="w-full">{}</h1>
                                <h1 id="text2" class="w-34">{}</h1>
                            </div>

                            <svg id="filters" class="h-12">
                                <defs>
                                    <filter id="threshold">
                                        <feColorMatrix in="SourceGraphic" type="matrix" values="1 0 0 0 0
                  0 1 0 0 0
                  0 0 1 0 0
                  0 0 0 255 -140" />
                                    </filter>
                                </defs>
                            </svg>
                            <div class="flex justify-center h-0 opacity-0 logo-in-container-mobile">
                              <img src={require("../assets/logo.png")} class=" w-48" alt="logo"></img>
                            </div>
                            <h1 class="font-title text-white t-64 -mt-4 I-t-- opacity-0">MSA ELEKTER</h1>
                            <div class="flex  -mt-3 pl-1 I-f-- opacity-0">
                                <h1 class="font-sec text-white">4.7</h1>
                                <img alt="star" class="w-5 h-5 ml-1 mr-1" src={require("../assets/star.png")}></img>
                                <h1 class="font-sec text-white mr-1">|</h1>
                                <a class="t-p-1 font-sec" href="https://www.inforegister.ee/12010742-MSA-ELEKTER-OU">inforegister.ee</a>
                            </div>
                            <div class="show-on-mobile opacity-0 flex justify-center mt-3">
                              <img onClick={()=> window.location.href = "https://www.instagram.com/msa_elekter"} class="w-10 h-10 rounded-full p-1 mr-2 ml-2 nav-btn-social cursor-pointer" alt="" src={require("../assets/icons8-instagram-96.png")}></img>
                              <img onClick={()=> window.location.href = "https://www.facebook.com/profile.php?id=100057395652440"} class="w-10 h-10 rounded-full p-1 nav-btn-social cursor-pointer" alt="" src={require("../assets/icons8-facebook-60.png")}></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="h-screen">{ }</div>
                <div class="b-2 p-24 container-I">
                    <h1 id="title-ettevõtte" class="text-white font-title pb-1 t-36 down txt-h-e opacity-0">ETTEVÕTTEST</h1>
                    <div class="down sep-e opacity-0">
                    <Separator side={"left"}></Separator>
                    </div>
                    <p class=" overflow-hidden down txt-p-e pt-4 font-sec text-white w-2/3 t-26 opacity-0">MSA Elekter OÜ on 2010. aastal Eestis loodud elektritöid teostav ettevõte. Ettevõte asub küll Viljandis aga töid teostame üle Eesti. Peamiseks tegevusvaldkonnasks on elektritööd ja automaatika.</p>
                </div>
                <img alt="" class="deco-I absolute opacity-0 right-0 -mt-64 w-96" src={require("../assets/deco-1.png")} />
                <div id="container-II" class="b-1 p-24">
                    <div class="container-w-adjust w-2/3 relative">
                        <h1 id="title-teenused" class="text-white font-title pb-1 t-36 text-right w-full down txt-h-t opacity-0">TEENUSED</h1>
                        <div class="absolute right-0 down sep-t opacity-0">
                            <Separator side={"right"}></Separator>
                        </div>
                        <p class="txt-p-t down pt-8 font-sec text-white w-full t-26 text-right opacity-0">Elektritööd • <br /> Elektrikilpide valmistamine • <br />Käidukorraldus • <br />Automaatika lahendused • <br />Elektrialane konsultatsioon •</p>
                    </div>
                </div>
                <div id="container-III" class="b-2 p-24 pl-32 pr-32">
                    <h1 id="title-kontakt" class="text-white font-title pb-1 t-36">KONTAKT</h1>
                    <Separator side={"left"}></Separator>
                    <div class="w-full h-1/2 mobile-flex-none">
                        <div class="b-1 w-1/2 h-full rounded-md p-12 mt-6 contact-container">
                            <h1 class="font-title text-white text-3xl">MEELIS ANTON</h1>
                            <h1 class="font-sec text-white">Juhataja. Elektritööd</h1>
                            <div class="b-3 rounded-xl pl-3 pt-2 pb-2 mt-4">
                                <div class="flex">
                                    <img alt="mail-icon" src={require("../assets/mail.png")}></img>
                                    <h1 class="text-white font-sec ml-1">Meiliaadress</h1>
                                </div>
                                <h1 class="text-white font-sec">meelis@msaelekter.ee</h1>
                            </div>
                            <div class="pt-4">{ }</div>
                            <div class="b-3 rounded-xl pl-3 pt-2 pb-2 w-">
                                <div class="flex">
                                    <img alt="phone-ico" class="h-6" src={require("../assets/phone.png")}></img>
                                    <h1 class="text-white font-sec ml-1">Telefoni nr</h1>
                                </div>
                                <h1 class="text-white font-sec">5212900</h1>
                            </div>
                        </div>
                        <div class="p-8 mobile-less-padding">{ }</div>
                        <div class="b-1 w-1/2 h-full rounded-md p-12 mt-6 contact-container">
                            <h1 class="font-title text-white text-3xl">RAUL HUNT</h1>
                            <h1 class="font-sec text-white">Juhataja. Elektritööd</h1>
                            <div class="b-3 rounded-xl pl-3 pt-2 pb-2 mt-4">
                                <div class="flex">
                                    <img alt="mail-icon" src={require("../assets/mail.png")}></img>
                                    <h1 class="text-white font-sec ml-1">Meiliaadress</h1>
                                </div>
                                <h1 class="text-white font-sec">raul@msaelekter.ee</h1>
                            </div>
                            <div class="pt-4">{ }</div>
                            <div class="b-3 rounded-xl pl-3 pt-2 pb-2">
                                <div class="flex">
                                    <img alt="phone-ico" class="h-6" src={require("../assets/phone.png")}></img>
                                    <h1 class="text-white font-sec ml-1">Telefoni nr</h1>
                                </div>
                                <h1 class="text-white font-sec">5212900</h1>
                            </div>
                        </div>
                    </div>
                    <div class="w-full h-96 b-1 rounded-2xl mt-12 flex items-center relative">
                        <div class="absolute right-0 h-full">
                            <img alt="building" class="h-full rounded-2xl banner-img-building" src={require("../assets/img.png")}></img>
                        </div>
                        <div class="ml-12">
                        <h1 class=" text-white font-title text-4xl mb-2 text-mobile-view-container-place">MSA Elekter OÜ</h1>
                        <p class="text-white font-sec text-xl text-mobile-view-container-place p-mobile">
                            Puiestee 10/1 <br/>
                            Viljandi 71012 <br/>
                            Reg.nr. 12010742 <br/>
                            EE101411271 <br/>
                            IBAN: EE192200221050845751 <br/>
                            info@msaelekter.ee <br/>
                        </p>
                        </div>
                    </div>
                </div>
                <div id="container-IV" class="b-1 p-24">
                    <h1 id="title-projektimüük" class="text-white font-title pb-1 t-36 txt-h-p opacity-0 down">PROJEKTIMÜÜK</h1>
                    <div class="down sep-p opacity-0">
                      <Separator side={"left"}></Separator>
                    </div>
                    <p class="pt-4 font-sec text-white w-2/3 t-26 txt-p-p opacity-0 drop-shadow-none">Küsige pakkumist. <br /> meelis@msaelekter.ee <br /> tel: 5212900</p>
                </div>
                <Footer></Footer>
            </div>
            <div class="absolute top-0 h-12 w-full">
                <Navbar></Navbar>
            </div>
        </div>
    )
}

export default Home;